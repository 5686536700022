import React from 'react';

import './index.scss';

function Chip (props) {
  return (
    <div className="Chip">{props.children}</div>
  )
}

export default Chip
