import React, { Component } from 'react';

import profilePicture from './assets/antontanderup.jpeg';
import './index.scss';

import Bubble from './../Bubble';
import Chip from './../Chip';

class ProfileCard extends Component {
  constructor(props) {
    super();
    this.state = {
      jiggelyBubble: null,
      width: null,
    };
    this.jiggleTimeout = this.jiggleTimeout.bind(this);
    this.initCard = this.initCard.bind(this);
    this.setWidth = this.setWidth.bind(this);
    this.card = null;
  }

  componentDidMount() {
    this.mounted = true;
    this.jiggleTimeout();
    window.addEventListener('resize', this.setWidth);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.setWidth);
  }

  setWidth() {
    if (!this.card) return;
    this.setState({ width: this.card.clientWidth });
    console.log(this.state.width);
  }

  initCard(elem) {
    if (!elem) return;
    this.card = elem;
    setTimeout(() => {
      this.setWidth();
    },0); 
  }

  jiggleTimeout() {
      setTimeout(()=> {
        if (this.mounted) {
          this.setState({ jiggelyBubble: Math.floor( Math.random() * (3 - 0 + 1) ) });
          this.jiggleTimeout();
        }
      }, 5000);
    
  }

  render() {
    const { jiggelyBubble } = this.state;
    return (
      <div className="ProfileCard" ref={this.initCard}>
        <div className="ProfileCard__top">
          <img src={profilePicture} className="ProfileCard__top__profile-picture" alt="logo" />
          <div className="ProfileCard__top__right">
            <h1>Anton Tanderup</h1>
            <p className="ProfileCard__top__right__work">Frontend developer at <br/><a href="http://zetland.dk/">Zetland.dk</a></p>
            <div className="ProfileCard__top__right__bubbles">
              <Bubble jiggle={jiggelyBubble === 0}/>
              <Bubble jiggle={jiggelyBubble === 1}/>
              <Bubble jiggle={jiggelyBubble === 2}/>
              <Bubble jiggle={jiggelyBubble === 3}/>
            </div>
            { this.state.width > 490 && (
              <p className="ProfileCard__top__right__education">MSc, Digital Design & Communication (ITU)<br/>BA, International Communication & Multimedia</p>
            ) }
          </div>
        </div>
        { this.state.width <= 490 && (
          <p className="ProfileCard__top__right__education">MSc, Digital Design & Communication (ITU)<br/>BA, International Communication & Multimedia</p>
        ) }
        <div className="ProfileCard__chips">
          <Chip>React</Chip>
          <Chip>React Native</Chip>
          <Chip>Redux</Chip>
          <Chip>JS/ES6</Chip>
          <Chip>TypeScript</Chip>
          <Chip>HTML</Chip>
          <Chip>CSS/Sass</Chip>
          <Chip>Git</Chip>
          <Chip>Interaction Design</Chip>
          <Chip>Graphic Design</Chip>
          <Chip>Illustrator</Chip>
        </div>
        
      </div>
    );
  }
}

export default ProfileCard
