import React, { Component } from "react";

class Video extends Component {
  render() {
    const { videoSource, format } = this.props;
    return (
      <video muted autoPlay loop>
        <source src={videoSource} type={format}/>
      </video>
    );
  }
}

export default Video;
