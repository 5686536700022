import React, { Component } from 'react';

import './index.scss';

import Chip from './../Chip';
import PortfolioController from './../PortfolioController';
import Video from './../Video';

import reachableMenu from './assets/Reachablemenu720.m4v';
import animatedSplashScreen from './assets/animatedSplashScreen720.mp4';
import apponboarding from './assets/Apponboarding720.m4v';
import webOnboardingSvg from './assets/Webonboardingsvg720.m4v';
import svgPlayPause from './assets/Svgplaypause720.m4v';

class Portfolio extends Component {
  constructor(props) {
    super();
    this.state = {
      activeIndex: 0,
      rotating: true,
      height: null,
    }

    this.rotator = this.rotator.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.setIndex = this.setIndex.bind(this);
    this.getElementHeight = this.getElementHeight.bind(this);

    this.slidesElements = [];
    this.slides = [
      {
        index: 0,
        videoSource: reachableMenu, 
        format: 'video/mp4', 
        title: 'React Native reachable contex menu',
        description: 'An always reachable React Native context menu, made by caputuring the coordinates of the users touch, and animating in buttons from these coordinates.',
        technology: [ 'react-native', 'react-native-svg', 'js', 'redux' ],
        type: 'portrait',
      },
      {
        index: 1,
        videoSource: animatedSplashScreen, 
        format: 'video/mp4', 
        title: 'React Native animated splash screen',
        description: 'Splash screen that transitions smoothly from a static splashscreen to an animated react native view.',
        technology: [ 'react-native', 'react-native-splash-screen', 'js', 'redux' ],
        type: 'portrait',
      },
      {
        index: 2,
        videoSource: apponboarding, 
        format: 'video/mp4', 
        title: 'React Native app onboarding showing actual app',
        description: 'App onboarding using a scaled down version of the running app, to enable a smooth transition to the app.',
        technology: [ 'react-native', 'react-native-swiper', 'js', 'redux' ],
        type: 'portrait',
      },
      {
        index: 3,
        videoSource: webOnboardingSvg, 
        format: 'video/mp4', 
        title: 'Onboarding flow made with react and svg animations',
        description: 'Onboarding that introduces users email notifications, and core features of the product.',
        technology: [ 'react-native', 'svg', 'js', 'html', 'scss', 'redux' ],
        type: 'wide',
      },
      {
        index: 4,
        videoSource: svgPlayPause, 
        format: 'video/mp4', 
        title: 'Animated play/pause button made with svg animation in react',
        description: 'Play pause button made with css animations and svg ',
        technology: [ 'react-native', 'svg', 'js', 'html', 'scss' ],
        type: 'wide',
      }
    ];
  }
  
  componentDidMount() {
    this.mounted = true;
    this.start();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  rotator(init = false) {
    if (!this.mounted);
    const { rotating, activeIndex } = this.state;
    const slides = this.slides;
    if (rotating) {
      setTimeout(() => {
        if (init) this.rotator();
        else if (activeIndex < slides.length - 1) {
          this.setState({activeIndex: activeIndex + 1})
          this.rotator();
        } else {
          this.setState({activeIndex: 0})
          this.rotator();
        }
      }, 10000);
    }
  }

  setIndex(index) {
    this.setState({activeIndex: index});
    this.stop();
  }

  stop() {
    this.setState({rotating: false});
  }
  
  start() {
    this.setState({rotating: true});
    this.rotator(true);
  }

  setElement(elem, index) {
    if (!elem || !this.mounted) return;
    this.slidesElements[index] = elem;
  }

  getElementHeight(elem, index) {
    const additionalHeight = this.slides[index].type === 'wide' ? 25 : 40;
    return elem.clientHeight + additionalHeight;
  }

  renderPortraitVideoSlide = ({index, videoSource, format, title, description, technology}) => {
    return (
      <div ref={(elem) => this.setElement(elem, index)} key={index} style={{opacity: index === this.state.activeIndex ? 1 : 0}} className="Portfolio__app-video-slide">
        <Video videoSource={videoSource} format={format} />
        <div className="Portfolio__app-video-slide__right">
          <h2>{title}</h2>
          <div className="Portfolio__app-video-slide__right__chips">
            {technology.map((item) => {
              return <Chip key={item}>{item}</Chip>
            })}
          </div>
          <p>{description}</p>
        </div>
      </div>
    );
  }
  renderWideVideoSlide = ({index, videoSource, format, title, description, technology}) => {
    return (
      <div key={index} style={{opacity: index === this.state.activeIndex ? 1 : 0}} className="Portfolio__app-video-slide Portfolio__app-video-slide--wide">
        <div ref={(elem) => this.setElement(elem, index)}>
          <h2>{title}</h2>
          <Video videoSource={videoSource} format={format} />
          <div className="Portfolio__app-video-slide__right">
            <div className="Portfolio__app-video-slide__right__chips">
              {technology.map((item) => {
                return <Chip key={item}>{item}</Chip>
              })}
            </div>
            <p>{description}</p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const slides = this.slides;
    const { activeIndex, rotating } = this.state;
    const styles = this.slidesElements[activeIndex] ? { height: this.getElementHeight(this.slidesElements[activeIndex], activeIndex) } : {};
    return (
      <div style={styles} className="Portfolio">
        <PortfolioController play={this.start} pause={this.stop} playing={rotating} currentIndex={activeIndex} items={slides} setCurrentIndex={this.setIndex} />
        <div className="Portfolio__slides">
          {slides.map((item) => {
            if (item.type === 'wide') return this.renderWideVideoSlide(item);
            else return this.renderPortraitVideoSlide(item);
          })}
        </div>
      </div>
    );
  }
}

export default Portfolio
