import React, { Component } from 'react';
import './App.scss';

import ProfileCard from './components/ProfileCard';
import Portfolio from './components/Portfolio';

class App extends Component {

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <ProfileCard />
        </header>
        <Portfolio />
      </div>
    );
  }
}

export default App;
