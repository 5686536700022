import React from 'react';

import './index.scss';

function Bubble ({jiggle}) {

  const width = 25;

  const style = {
    width,
    height: width,
    transform:`scale(0.${Math.floor(Math.random() * (9 - 6 + 1)) + 6})`,
  }

  const classes = 'Bubble' + (jiggle ? ' jiggle' : '')

  return (
    <div className="Bubble--wrap">
      <div style={style} className={classes} />
    </div>
    
  )
}

export default Bubble