import React from 'react';
import { ReactSVG } from 'react-svg'

import './index.scss';

import playSvg from './assets/play.svg';
import pauseSvg from './assets/pause.svg';

function PortfolioController ({play, pause, playing, currentIndex, items, setCurrentIndex}) {

  const renderItem = (index) => {
    const isCurrent = index === currentIndex;
    return (
      <button key={index} className={'PortfolioController__item' + (isCurrent ? ' active' : '') } onClick={() => setCurrentIndex(index)}>{index + 1}</button>
    );
  }

  return (
    <div className="PortfolioController">
      {items.map((item, index) => {
        return renderItem(index);
      })}
      <button className='PortfolioController__item' onClick={playing ? () => pause() : () => play()}>
          <ReactSVG
            src={playing ? pauseSvg : playSvg}
          />
        </button>
    </div>
  );
}

export default PortfolioController